import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/nprogress/styles.css";
import "@mantine/notifications/styles.css";
import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { nprogress, NavigationProgress } from "@mantine/nprogress";
import { theme } from "./themes/theme-with-components";
import { ModalsProvider } from "@mantine/modals";
import "./themes/theme.css";
import { MetaFunction } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useNavigation,
} from "@remix-run/react";
import { useEffect } from "react";
import _ from "lodash";

export const meta: MetaFunction = () => {
  return [
    { title: "Air Video Style" },
    { name: "description", content: "Video Style Transfer" },
  ];
};

export default function App() {
  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files

  let { state: navigationState } = useNavigation();

  useEffect(() => {
    if (navigationState !== "idle") {
      nprogress.start();
    } else {
      nprogress.complete();
    }
  }, [navigationState]);

  return (
    <html>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <Meta />
        <Links />
        <ColorSchemeScript defaultColorScheme="light" />
      </head>
      <body>
        <MantineProvider defaultColorScheme="light" theme={theme}>
          <NavigationProgress />
          <Notifications position="top-center"></Notifications>
          <ModalsProvider>
            <Outlet></Outlet>
          </ModalsProvider>
          <ScrollRestoration />
          <Scripts />
        </MantineProvider>
      </body>
    </html>
  );
}
export const shouldRevalidate: ShouldRevalidateFunction = ({
  actionResult,
  defaultShouldRevalidate,
}) => {
  return false;
};
