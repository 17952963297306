import { MantineColorsTuple, createTheme } from "@mantine/core";

export const theme = createTheme({
  primaryColor: "teal",
  colors: {
    teal: [
      "#E6FFF6",
      "#CCFFE8",
      "#A8FFE5",
      "#78FFEA",
      "#4DFFD5",
      "#2BFFC2",
      "#12D8C3",
      "#06E8AC",
      "#0CE8A7",
      "#10DFA2",
    ],
  },
});
