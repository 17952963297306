import { AppShell, Input, Tabs, TextInput } from "@mantine/core";
import { theme } from "./theme";
import appshell from "./appshell.module.css";
import tabs from "./tabs.module.css";
theme.components = {
  AppShell: AppShell.extend({
    classNames: appshell,
  }),
  Tabs: Tabs.extend({
    classNames: tabs,
  }),
  Input: Input.extend({
    defaultProps: {
      size: "xs",
    },
  }),
};
export { theme };
